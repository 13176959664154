<template>
  <div id="section4">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container class="text-center">
          <br />
          <h2 class="text-left">
            <div v-for="(item, idx) in payTitulos" :key="idx">{{item}}</div>
          </h2>
          <br />
          <b-row align-h="around" align-v="center">
            <b-col md="3" sm="12" class="text-center" v-for="(item, idx) in payList" :key="idx">
              <a @click="menuClicked(item.btnUrl)" class="btn">
                <b-img :src="item.btnImg" style="width: 80%; height: auto;" />
              </a>
            </b-col>
          </b-row>
          <br />
          <br />
          <a class="btn buttonTurqueza" @click="menuClicked(paybtnUrl)" target="_blank">{{$t("paybtnTxt")}}</a>
          <br />
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulos": [
      "Payment acceptance solutions for your business.",
      "The limit is up to you."
    ],
    "paybtnTxt": "MORE INFORMATION"
  },
  "es":{
    "payTitulos": [
      "Soluciones de aceptación de pago para tu negocio.",
      "El límite lo pones tú."
    ],
    "paybtnTxt": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payList: [
        {
          btnImg: require("@/assets/images/pay/icon_home00_hover.png"),
          btnUrl: "page_pay1.1"
        },
        {
          btnImg: require("@/assets/images/pay/icon_home01_hover.png"),
          btnUrl: "page_pay1.2"
        },
        {
          btnImg: require("@/assets/images/pay/icon_home02_hover.png"),
          btnUrl: "page_pay1.3"
        },
        {
          btnImg: require("@/assets/images/pay/icon_home03_hover.png"),
          btnUrl: "page_pay1.3"
        }
      ],
      paybtnUrl: "page_pay1"
    };
  },
  computed: {
    payTitulos() {
      return this.$t("payTitulos");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>