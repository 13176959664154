<template>
  <div id="section2">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
            <h2>
              <span v-for="(item, idx) in paytitulo" :key="idx" class="mb-1">
                <strong v-if="item.textS">
                  {{item.text}}
                </strong>
                <span v-else>
                  {{item.text}}
                </span>
              </span>
            </h2>
            <p class="text-justify">
              <span v-for="(item, idx) in paytexts" :key="idx">
                <strong v-if="item.textS">
                  {{item.text}}
                </strong>
                <span v-else>
                  {{item.text}}
                </span>
              </span>
            </p>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "paytitulo": [
      {
        "textS": true
        , "text": "Prepare your business to accept digital payments."
      }
    ],
    "paytexts": [
      {
        "textS": false
        , "text": "The consumer pays with any card, by phone, from home, with the mobile. We provide companies and payment providers with solutions at the point of sale, ecommerce and omnichannel. Without the need to hire other bank products, you may be able to settle payments in any bank account."
      }
    ]
  },
  "es":{
    "paytitulo": [
      {
        "textS": true
        , "text": "Prepara tu negocio para aceptar pagos digitales."
      }
    ],
    "paytexts": [
      {
        "textS": false
        , "text": "El consumidor paga con cualquier tarjeta, por teléfono, desde casa, con el móvil. Aportamos a las empresas y proveedores de pagos soluciones en el punto de venta, ecommerce y omnicanal. Sin necesidad de contratar otros productos bancarios, pudiendo liquidar los pagos en cualquier cuenta bancaria."
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    paytitulo() {
      return this.$t("paytitulo");
    },
    paytexts() {
      return this.$t("paytexts");
    }
  }
};
</script>