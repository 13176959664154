<template>
  <div id="section3">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container class="text-center">
          <br />
          <b-row align-h="around" align-v="center">
            <b-col md="4" sm="12" class="text-center" v-for="(item, idx) in payList" :key="idx">
              <b-row>
                <b-col cols="12" align-self="start">
                  <h3>
                    <strong>{{item.titulo}}</strong>
                  </h3>
                  <br />
                </b-col>
                <b-col cols="12" align-self="center">
                  <p class="text-center">{{item.texto}}</p>
                </b-col>
                <b-col cols="12" align-self="end">
                  <br />
                  <a class="btn buttonTurqueza" 
                   @click="menuClicked(item.btnUrl)">{{item.btnTxt}}</a>
                </b-col>
              </b-row>
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payList": [
      {
        "titulo": "Flat Rates"
        , "texto": "Accept card payments and don't worry about the cost. From € 19.99 /a month for € 1,000 in transactions. Terminal included."
        , "btnTxt": "MORE INFORMATION"
        , "btnUrl": "#"
      },
      {
        "titulo": "The future of payment methods 2019"
        , "texto": "Meet the latest trends in payment methods and get the most from your business"
        , "btnTxt": "MORE INFORMATION"
        , "btnUrl": "page_pay6"
      }
    ]
  },
  "es":{
    "payList": [
      {
        "titulo": "Tarifas Planas"
        , "texto": "Acepta pagos con tarjeta y despreocúpate del coste. Desde 19,99 €/mes por 1.000€ en transacciones. Terminal incluido."
        , "btnTxt": "MÁS INFORMACIÓN"
        , "btnUrl": "#"
      },
      {
        "titulo": "El futuro de los medios de pago 2019"
        , "texto": "Conoce las últimas tendencias en medios de pago y sácale todo el partido a tu negocio"
        , "btnTxt": "MÁS INFORMACIÓN"
        , "btnUrl": "page_pay6"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>