<template>
  <div class="payInicio">
    <div class="wrapper">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/pay/home/Section1";
import Section2 from "@/components/pay/home/Section2";
import Section3 from "@/components/pay/home/Section3";
import Section4 from "@/components/pay/home/Section4";
import Section5 from "@/components/pay/home/Section5";
import Section6 from "@/components/pay/home/Section6";
import Section7 from "@/components/pay/home/Section7";
import Section8 from "@/components/pay/home/Section8";

export default {
  name: "payInicio",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    Section8,
  },
  head: {
    title: {
      inner: "Inicio PAY"
    },
  },
  mounted() {
    this.$i18n.locale = "es";
  }
};
</script>