<template>
  <div id="section7">
    <div class="fondoWhite">
      <div class="wrapper">
        <b-container fluid class="payCarrusel">
          <b-container class="text-center pb-3">
            <carousel
              :autoplay="true"
              :autoplayTimeout="3000"
              :autoplayHoverPause="true"
              :loop="true"
              :perPage="1"
            >
              <slide v-for="(item, idx) in payitems" :key="idx">
                <div class="HeadContainer-small">
                  <div class="HeadVertical-center">
                    <h2>{{item.title}}</h2>
                    <br />
                    <p>{{item.autor}}</p>
                  </div>
                </div>
              </slide>
            </carousel>
          </b-container>
        </b-container>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payitems": [
      {
        "title": "“It works correctly, very attentive service, great.”",
        "autor": "Villa Las Flores (Playa de las Américas, Tenerife)"
      },
      {
        "title": "“The attention and service are very good.”",
        "autor": "Eco Seny (Blanes, Girona)"
      },
      {
        "title":
          "“From 10. For the trust and reliability in the transactions, seriousness and immediacy in the resolution of any problems derived from anomalies in the system.”",
        "autor": "Casa Bracamonte residence pension. (Mondoñedo, Lugo)"
      },
      {
        "title":
          "“My assessment is a 9 due to the efficiency and speed of the technical service, as well as the clarifications to any doubts that arise.”",
        "autor": "VERDECORA (Alcalá de Henares)"
      },
      {
        "title":
          "“I would recommend UniversalPay for its great customer service.”",
        "autor": "KRIPLUS HYPERMEDIA S.L. (Madrid)"
      },
      {
        "title":
          "“A very personal treatment and with with tranquility knowing that your problem will be solved.”",
        "autor": "ROSARIO GARCÍA OLAYA (Malpartida, Cáceres)"
      },
      {
        "title":
          "“Comfortable, fast and efficient. Before we relied on customers making transfers. Now the process is accelerated and we have fewer defaults.”",
        "autor": "CALORTER S.L. (Valladolid)"
      },
      {
        "title":
          "“They always give excellent services and on excellent conditions.”",
        "autor": "Viajes Trenpy (Palma de Mallorca)"
      },
      {
        "title":
          "“I would recommend UniversalPay for its great customer service.”",
        "autor": "KRIPLUS HYPERMEDIA SL (Madrid)"
      },
      {
        "title":
          "“Very good telephone assistance when you have a problem with the POS.”",
        "autor": "Le Modèle (Madrid)"
      },
      {
        "title":
          "“I recommend UniversalPal for ease and adaptation to all customers. Every one has a different situation and this company adapts to each one.”",
        "autor": "L'Obrador de Martina (Alcoy, Alicante)"
      }
    ]
  },
  "es":{
    "payitems": [
      {
        "title": "“Funciona correctamente, servicio muy atento, genial.”",
        "autor": "Villa Las Flores (Playa de las Américas, Tenerife)"
      },
      {
        "title": "“La atención y el servicio está siendo muy bueno.”",
        "autor": "Eco Seny (Blanes, Girona)"
      },
      {
        "title":
          "“De 10. Por la confianza y fiabilidad en las transacciones, seriedad e inmediatez en la resolución ante cualquier problema derivada de anomalías en el sistema.”",
        "autor": "Pensión residencia Casa Bracamonte. (Mondoñedo, Lugo)"
      },
      {
        "title":
          "“Mi valoración es un 9 debido a la eficacia y rapidez del servicio técnico, así como por las aclaraciones en cualquier tipo de duda que se les consulta.”",
        "autor": "VERDECORA (Alcalá de Henares)"
      },
      {
        "title":
          "“Recomendaría UniversalPay por su buena atención al cliente.”",
        "autor": "KRIPLUS HYPERMEDIA S.L. (Madrid)"
      },
      {
        "title":
          "“Un trato muy personal y con la tranquilidad de saber que te pueden solucionar un problema.”",
        "autor": "ROSARIO GARCÍA OLAYA (Malpartida, Cáceres)"
      },
      {
        "title":
          "“Cómodo, rápido y eficaz. Antes dependíamos de que los clientes nos hicieran transferencias. Ahora se agiliza el proceso y tenemos menos impagos.”",
        "autor": "CALORTER S.L. (Valladolid)"
      },
      {
        "title":
          "“Siempre dan servicios excelentes y en excelentes condiciones.”",
        "autor": "Viajes Trenpy (Palma de Mallorca)"
      },
      {
        "title":
          "“Recomendaría UniversalPay por su buena atención al cliente.”",
        "autor": "KRIPLUS HYPERMEDIA SL (Madrid)"
      },
      {
        "title":
          "“Muy buena atención telefónica cuando tienes un problema con la TPV.”",
        "autor": "Le Modèle (Madrid)"
      },
      {
        "title":
          "“Recomiendo UniversalPay por la facilidad y la adaptación con todos los clientes. Cada cual tiene una situación diferente y esta empresa se amolda a cada uno.”",
        "autor": "L'Obrador de Martina (Alcoy, Alicante)"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  computed: {
    payitems() {
      return this.$t("payitems");
    }
  }
};
</script>
