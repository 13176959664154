<template>
  <div id="section8">
    <div class="fondoGris" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
            <b-row align-h="center" align-v="center" v-responsive.md.lg.xl>
              <b-col md="4" class="text-right">
                <div class="d-flex justify-content-end">
                  <b-img :src="payIcon"/>
                </div>
                <br>
                <h3>
                  {{$t("payTitulo")}}
                </h3>
                <br>
                <p>
                  {{$t("payText")}}
                </p>
                <br>
                <a class="btn buttonTurqueza" @click="menuClicked(payBtnUrl)" target="_blank">{{$t("payBtnTxt")}}</a>
                <br />
              </b-col>
              <b-col md="5" class="text-left">
                <b-img :src="payImg"/>
              </b-col>
            </b-row>
            <b-row align-h="center" align-v="center" v-responsive.sm.xs>
              <b-col sm="12" class="text-center">
                <div class="d-flex justify-content-center">
                  <b-img :src="payIcon"/>
                </div>
                <br />
                <h3>
                  {{$t("payTitulo")}}
                </h3>
                <br>
                <p>
                  {{$t("payText")}}
                </p>
                <br />
                <a class="btn buttonTurqueza" @click="menuClicked(payBtnUrl)" >{{$t("payBtnTxt")}}</a>
                <br />
              </b-col>
              <b-col sm="12" class="text-center">
                <b-img :src="payImg"/>
              </b-col>
            </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Tools and toolkits designed for developers."
    , "payText": "Simplify online store and mobile payments with applications and toolkits designed for developers."
    , "payBtnTxt": "SEE MORE"
  },
  "es":{
    "payTitulo": "Herramientas y toolkits pensados para desarrolladores."
    , "payText": "Simplifica los pagos en tienda online y mediante móvil con aplicaciones y toolkits pensadas para desarrolladores."
    , "payBtnTxt": "VER MÁS"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: "https://www.universalpay.es/wp-content/uploads/Snap.png"
      , payBtnUrl: "page_pay3"
      , payImg: "https://www.universalpay.es/wp-content/uploads/Snap-software-companies-developers.png"
    };
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>