<template>
  <div id="section6">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br />
            <h2>
              <span v-for="(item, idx) in paytitulo" :key="idx" class="mb-1">
                <strong v-if="item.textS">
                  {{item.text}}
                </strong>
                <span v-else>
                  {{item.text}}
                </span>
              </span>
            </h2>
            <p class="text-justify">
              <span v-for="(item, idx) in paytexts" :key="idx">
                <strong v-if="item.textS">
                  {{item.text}}
                </strong>
                <span v-else>
                  {{item.text}}
                </span>
              </span>
            </p>
            <br>
            <b-row align-h="center" align-v="center">
              <b-col v-for="(item, idx) in payImg" :key="idx" class="text-center">
                <b-img :src="item"/>
              </b-col>
            </b-row>
          <br />
            <h3 >
              <span v-for="(item, idx) in paytitulo1" :key="idx" class="mb-1">
                <strong v-if="item.textS">
                  {{item.text}}
                </strong>
                <span v-else>
                  {{item.text}}
                </span>
              </span>
            </h3>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "paytitulo": [
      {
        "textS": true
        , "text": "Get to know us through their stories."
      }
    ],
    "paytitulo1": [
      {
        "textS": true
        , "text": "What they say about us …"
      }
    ],
    "paytexts": [
      {
        "textS": false
        , "text": "In 2018 we have served more than 100,000 customers who chose us to process more than 10 billion euros. "
      },
      {
        "textS": false
        , "text": "Among which are:"
      }
    ]
  },
  "es":{
    "paytitulo": [
      {
        "textS": true
        , "text": "Conócenos a través de sus historias."
      }
    ],
    "paytitulo1": [
      {
        "textS": true
        , "text": "Lo que dicen de nosotros…"
      }
    ],
    "paytexts": [
      {
        "textS": false
        , "text": "En 2018 hemos dado servicio a más de 100.000 clientes que nos eligieron para procesar más de 10 mil millones de euros."
      },
      {
        "textS": false
        , "text": "Entre los que se encuentran:"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: [
        "https://www.universalpay.es/wp-content/uploads/rumbo.png"
        , "https://www.universalpay.es/wp-content/uploads/allianz-logo.png"
        , "https://www.universalpay.es/wp-content/uploads/yves-rocher-logo.png"
      ]
    };
  },
  computed: {
    paytitulo() {
      return this.$t("paytitulo");
    },
    paytitulo1() {
      return this.$t("paytitulo1");
    },
    paytexts() {
      return this.$t("paytexts");
    }
  }
};
</script>