<template>
  <div id="section5">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
          <h2  class="text-left">
            <div v-for="(item, idx) in payTitulos" :key="idx">
            {{item}}
            </div>
          </h2>
          <br />
            <b-row align-h="center">
              <b-col md="4" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center">
                  <a
                    class="btn paybtnIcon"
                  >
                    <font-awesome-icon :icon="payListI[idx].btnImg" class="socialIconPay" />
                  </a>
                  <br>
                  <h3>
                    {{item.titulo}}
                  </h3>
                  <p>
                    {{item.texto}}
                  </p>
                </div>
                <br>
              </b-col>
            </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulos":[
      "Solutions adapted to your business."
    ],
    "payList": [
      {
        "titulo": "We accept all types of card"
        , "texto": "Accept payments by credit or debit cards of all brands, both domestic and foreign."
      },
      {
        "titulo": "With our safety commitment"
        , "texto": "We comply with the PCI-DSS security directive. In addition, you will have advanced fraud control systems."
      },
      {
        "titulo": "Personalized"
        , "texto": "We accompany you throughout the registration process, offering customized solutions in accordance with your needs."
      },
      {
        "titulo": "Sell worldwide"
        , "texto": "Accept currencies from 49 countries and settle in any bank account."
      },
      {
        "titulo": "Omnichannel"
        , "texto": "Accept payments in store, online, through mobile devices or with MOTO operations."
      },
      {
        "titulo": "With total transparency"
        , "texto": "No hidden charges on your national, intra-European or international payments."
      }
    ]
  },
  "es":{
    "payTitulos":[
      "Soluciones adaptadas a tu negocio."
    ],
    "payList": [
      {
        "titulo": "Aceptamos todos los tipos de tarjeta"
        , "texto": "Acepta pagos con tarjeta de crédito o débito tanto nacionales como extranjeras de todas las marcas."
      },
      {
        "titulo": "Con nuestro compromiso de seguridad"
        , "texto": "Cumplimos la directiva de seguridad PCI-DSS. Además, dispondrás de sistemas avanzados de control de fraude."
      },
      {
        "titulo": "Trato personalizado"
        , "texto": "Te acompañamos durante todo el proceso de alta, ofreciendo soluciones a medida de acuerdo a tus necesidades."
      },
      {
        "titulo": "Vende en todo el mundo"
        , "texto": "Acepta divisas de 49 países y liquida en cualquier cuenta bancaria."
      },
      {
        "titulo": "Omnicanalidad"
        , "texto": "Acepta pagos en tienda, online, a través de dispositivos móviles o con operativa MOTO."
      },
      {
        "titulo": "Con total transparencia"
        , "texto": "Sin cargos ocultos en tus pagos nacionales, intraeuropeos o internacionales."
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payListI: [
        {
          btnImg: ['fas','credit-card']
        },
        {
          btnImg: ['fas','lock']
        },
        {
          btnImg: ['fas','comment']
        },
        {
          btnImg: ['fas','globe-europe']
        },
        {
          btnImg: ['fas','expand-arrows-alt']
        },
        {
          btnImg: ['fas','search']
        }
      ]
    };
  },
  computed: {
    payTitulos() {
      return this.$t("payTitulos");
    },
    payList() {
      return this.$t("payList");
    }
  }
};
</script>