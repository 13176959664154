<template>
  <div id="section1">
    <div class="payFondo">
      <div class="fondoFwhite">
        <div class="payVideo" v-responsive.lg.xl>
          <video autoplay controls loop muted preload style="width: 100%">
            <source :src="payVideo" type="video/mp4" />
          </video>
        </div>   
        <div class="payImg" v-responsive.md.sm.xs>
          <b-img :src="payImg" class="payImg1"/>
        </div>   
        <b-container fluid>
          <b-container>
            <br />
            <b-row align-v="center" align-h="center" style="height: 50vh">
              <b-col md="6" sm="12" class="text-center align-self-center" >
                <h2>{{$t("paytitle")}}</h2>
              </b-col>
            </b-row>
            <br />
          </b-container>
        </b-container>
      </div>      
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "paytitle": "Payment solutions for all moments of purchase"
  },
  "es":{
    "paytitle": "Soluciones de pago para todos los momentos de compra"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payVideo: "https://google-my-business.aicad.es/images/video/genesis_480.mp4",
      payImg: require("@/assets/images/pay/img_header_pagosentienda.jpg")
    }
  }
};
</script>
